<template>
  <div align="center" class="ma-0 pa-0">
    <v-app-bar app clipped-left :height="isSmall ? menuHeight : menuHeight * 1.5" align-items="center">
      <!-- <v-app-bar-nav-icon @click="showMenu = !showMenu"></v-app-bar-nav-icon> -->
      <v-btn outlined text @click="showMenu = !showMenu">
        <v-icon class="mr-2">mdi-menu</v-icon>
        {{ isSmall ? "" : "Сподели" }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-card-title :class="isSmall ? 'caption' : ''">{{ title }}</v-card-title>
      <v-spacer></v-spacer>
      <v-btn outlined text @click="isOrdering = !isOrdering">
        <v-icon class="mr-2">{{ isOrdering ? "mdi-food" : "mdi-book-open-page-variant" }}</v-icon>
        {{ isSmall ? "(" + getItemsQuantitySum() + ")" : isOrdering ? "Заявка (" + getItemsQuantitySum() + ")" : "Към Менюто" }}
      </v-btn>
      <!-- <v-spacer></v-spacer>
      <v-card-subtitle><v-icon>mdi-google-maps</v-icon> Някакъв тест</v-card-subtitle> -->
    </v-app-bar>
    <v-navigation-drawer app v-model="showMenu" temporary width="300" class="ma-0 pa-0">
      <v-col cols="12" class="ma-0 pa-4">
        <qrcode-vue ref="refQR" :value="getUrlFullPath" :size="200" level="H" />
        <v-row class="ma-2 pa-2 mb-8" justify="space-around">
          <ShareNetwork v-for="n in socialNetworks" :key="n.title" :url="getUrlFullPath" :network="n.name" :title="n.title">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="blue accent-2" large class="ma-2 custom-icon">{{ n.icon }}</v-icon>
              </template>
              {{ n.hint }}
            </v-tooltip>
          </ShareNetwork>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="ma-2" icon @click="printQR">
                <v-icon color="blue accent-2" large>mdi-printer-wireless</v-icon>
              </v-btn>
            </template>
            Отпечатай QR кода
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="ma-2" icon @click="saveQR">
                <v-icon color="blue accent-2" large>mdi-content-save-outline</v-icon>
              </v-btn>
            </template>
            Запази QR кода като изображение
          </v-tooltip>
        </v-row>
        <v-switch class="ma-4 pa-0" v-model="showAlergens" dense label="Алергени" hide-details></v-switch>
        <v-switch class="ma-4 pa-0" v-model="$vuetify.theme.dark" @change="setDarkTheme" dense label="Тъмен режим" hide-details></v-switch>
      </v-col>
    </v-navigation-drawer>
    <!-- <v-bottom-navigation class="elevation-10" absolute> </v-bottom-navigation> -->
    <v-main app>
      <template v-if="isOrdering">
        <v-tabs height="64" class="ma-0 pa-0" :background-color="$vuetify.theme.dark ? '' : 'brown lighten-5'" center-active centered :show-arrows="true">
          <v-tabs-slider color="teal"></v-tabs-slider>
          <v-tab v-for="g in groups" :key="g.Name" @click="currentGroup = g">
            {{ g.Name }}
          </v-tab>
        </v-tabs>
        <v-container style="min-width: 100%; max-height: calc(100vh - 99px); height: calc(100vh - 99px); overflow-y: auto" class="ma-0 pa-0">
          <v-row class="ma-0 pa-0" justify="space-around">
            <v-card class="elevation-5 ma-2 pa-0" v-for="i in getItems" :key="i.Name" :width="itemWidth">
              <v-row class="ma-0 pa-0">
                <v-card-subtitle align="start" style="min-width: 100%; height: 76px; font-size: 16px">{{ i.Name }}</v-card-subtitle>
                <v-img class="ma-0 pa-0" style="border: 1px solid lightblue" contain :max-height="itemWidth * 0.7" :src="i.Image" aspect-ratio="1"></v-img>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-card-subtitle class="ma-0 pa-0 pa-1 px-3" align="start" style="height: 64px; font-size: 12px">{{ i.Description }}</v-card-subtitle>
                </v-col>
                <v-col cols="12" class="ma-0 pa-3" v-if="showAlergens">
                  <alergens-view class="ma-0 pa-0" :alergens="i.Alergens"></alergens-view>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-card-subtitle class="ma-2 pa-0 px-1" align="start" style="font-size: 16px">{{ i.Price.toFixed(2) + " лв." }}</v-card-subtitle>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0 mb-2" align-self="center" justify="end">
                  <v-btn color="info" width="auto" @click="addItem(i)">{{ getOrderButtonText(i) }}</v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-container>
      </template>
      <template v-else>
        <v-col cols="12">
          <template v-if="currentItems.length > 0">
            <v-card v-for="i in currentItems" :key="i.Name">
              <v-row class="ma-2 pa-2">
                <v-col justify="start" cols="12" lg="4" xl="4" md="4" sm="12" xs="12">
                  <v-row align="center" justify="start">
                    <span style="text-align: start; white-space: pre-wrap">{{ i.Name }}</span>
                  </v-row>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" justify="center">
                  <v-row align="center">
                    <v-slider v-model="i.Quantity" :max="20" hide-details></v-slider>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="2" xl="2" md="2" sm="12" xs="12">
                  <v-row align="center" justify="space-between">
                    <span style="text-align: start">{{ i.Quantity + " бр." }}</span>
                    <span style="text-align: end">{{ (i.Quantity * i.Price).toFixed(2) + " лв." }}</span>
                  </v-row>
                  <!-- <v-img class="ma-0 pa-0" contain :max-height="itemWidth / 4" :src="i.Image"></v-img> -->
                </v-col>
              </v-row>
            </v-card>
            <v-card>
              <v-row align="end" justify="end" class="ma-2 pa-2">
                <span class="subtitle-1">{{ "Общо: " + getItemsSum.toFixed(2) + " лв." }} </span>
              </v-row>
            </v-card>
          </template>
          <v-card v-else>
            <v-card-subtitle class="text-center">Няма избрани артикули</v-card-subtitle>
          </v-card>
        </v-col>
      </template>
    </v-main>
  </div>
</template>

<script>
import AlergensView from "@/components/AlergensView.vue";
import QrcodeVue from "qrcode.vue";
import { Printd } from "printd";
import api from "@/js/api";
import Vue from "vue";
// import ViberSvg from "@/assets/viber.svg";

const cssText = `
  h1 {
    color: black;
    font-family: sans-serif;
  }
`;

export default {
  components: {
    AlergensView,
    QrcodeVue,
  },
  data() {
    return {
      socialNetworks: [
        { name: "facebook", title: "Facebook", icon: "mdi-facebook", hint: "Сподели във Facebook" },
        { name: "messenger", title: "Messenger", icon: "mdi-facebook-messenger", hint: "Изпрати в Messenger" },
        { name: "twitter", title: "Twitter", icon: "mdi-twitter", hint: "Сподели в Twitter" },
        { name: "viber", title: "Viber", icon: "$viber", hint: "Сподели във Viber" },
        { name: "skype", title: "Skype", icon: "mdi-skype", hint: "Изпрати в Skype" },
        { name: "sms", title: "SMS", icon: "mdi-cellphone-message", hint: "Изпрати като SMS" },

        // { name: "viber", title: "Viber2", icon: "$viber2" },
        // { name: "viber", title: "Viber3", icon: "$viber3" },
      ],
      title: "",
      username: "test",
      itemWidth: 350,
      showMenu: false,
      showAlergens: true,
      isOrdering: true,
      currentGroup: null,
      groups: [],
      items: [],
    };
  },
  computed: {
    currentItems() {
      return [...this.items.filter((i) => i.Quantity > 0)];
    },
    getUrl() {
      return new URL(window.location.href);
    },
    getUrlFullPath() {
      return window.location.href;
    },
    getItems() {
      if (this.currentGroup == null) return [];
      else return this.items.filter((i) => i.GroupID == this.currentGroup.ID);
    },
    getItemsSum() {
      return this.currentItems
        .map((i) => i.Quantity * i.Price)
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    },
  },
  methods: {
    setDarkTheme() {
      localStorage.setItem("dark", this.$vuetify.theme.dark);
    },
    getOrderButtonText(i) {
      return i.Quantity == 0 ? "Добави" : "Добави " + "(" + i.Quantity + ")";
    },
    getItemsQuantitySum() {
      return this.currentItems
        .map((i) => i.Quantity)
        .reduce((a, b) => {
          return a + b;
        }, 0);
    },
    printQR() {
      const d = new Printd();
      /** @type {HTMLCanvasElement} */
      let canvas = this.$refs.refQR.$refs["qrcode-vue"];
      let img = document.createElement("img");
      img.setAttribute("id", "idQR");
      img.setAttribute("width", 200);
      img.setAttribute("height", 200);
      img.src = canvas.toDataURL("image/png");

      d.print(img, [cssText]);
      img.remove();

      // let canvas = this.$refs.refQR.$refs["qrcode-vue"];
      // let img = document.createElement("img");
      // img.setAttribute("id", "#idQR");
      // canvas.getContext("2d").drawImage(img, 200, 200);
      // this.$htmlToPaper("idQR");
      // img.remove();
    },
    async saveQR() {
      let canvas = this.$refs.refQR.$refs["qrcode-vue"];
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      // const options = {
      //   type: "dataURL",
      // };
      // let canvas = await this.$html2canvas(el, options);
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("download", "QR_Code.png");
      let dataURL = canvas.toDataURL("image/png");
      let url = dataURL.replace(/^data:image\/png/, "data:application/octet-stream");
      downloadLink.setAttribute("href", url);
      downloadLink.click();
      downloadLink.remove();
    },
    shareQR() {
      navigator.share({ url: this.getUrl });
    },
    getItemQuantitySum(item) {
      var i = this.currentItems.find((i) => i == item);
      if (!i) return "";
      else return "(" + i.Quantity + ")";
    },
    addItem(item) {
      if (item.Quantity < 20) item.Quantity++;
    },
    loadData() {
      this.showLoader("Зареждане на данните...", () => {
        api
          .get("users/publicsettings/" + this.$route.params.username)
          .then((users) => {
            this.title = users[0].StoreName;
            api
              .get("groups/foruser/" + this.$route.params.username)
              .then((groups) => {
                this.groups = groups;
                api
                  .get("items/foruser/" + this.$route.params.username)
                  .then((items) => {
                    this.items = items.filter((i) => i.Price > 0);
                    try {
                      this.items.forEach((i) => {
                        Vue.set(i, "Quantity", 0);
                        try {
                          i.Alergens = JSON.parse(i.Alergens);
                        } catch {
                          i.Alergens = [0];
                        }
                        i.Image = api.url + "images/" + this.$route.params.username + "/" + i.ID;
                        // i.Quantity = 0;
                      });
                    } catch {
                      /**ignored */
                    }

                    this.$nextTick(() => {
                      // console.log(this.$vuetify.icons);
                      if (this.groups.length > 0) this.currentGroup = this.groups[0];
                    });
                    this.hideLoader();
                  })
                  .catch((error) => {
                    this.showMessage("error", "Грешка при зареждане на артикули!\n" + error.message);
                  });
              })
              .catch((error) => {
                this.showMessage("error", "Грешка при зареждане на групи!\n" + error.message);
              });
          })
          .catch((error) => {
            this.showMessage("error", "Грешка при зареждане на информация!\n" + error.message);
          });
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadData();
      this.$vuetify.theme.dark = localStorage.getItem("dark") == "true";
    });
  },
};
</script>

<style scoped>
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
/* .v-tooltip__content {
  opacity: 1 !important;
} */
</style>
