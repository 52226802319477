<template>
  <div style="height: calc(100% - 1px); width: 100%">
    <v-navigation-drawer app permanent clipped>
      <v-card style="height: 100%; width: 100%" elevation="10"> </v-card>
    </v-navigation-drawer>
    <my-grid :items="desserts" :headers="headers"></my-grid>
  </div>
</template>

<script>
import { HeaderTypes } from "@/entities/HeaderTypes";
export default {
  data() {
    return {
      desserts: Array.from({ length: 5000 }).map((v, k) => ({
        name: `#${k}`,
        calories: 518,
        fat: 26.0,
        carbs: 65,
        protein: 7,
        iron: "6%",
      })),
      headers: [
        {
          text: "Dessert (100g serving)",
          align: "start",
          sortable: false,
          value: "name",
          divider: true,
        },
        { text: "Calories", value: "calories", divider: true },
        { text: "Fat (g)", value: "fat", divider: true },
        { text: "Carbs (g)", value: "carbs", divider: true },
        { text: "Protein (g)", value: "protein", divider: true },
        { text: "Iron (%)", value: "iron", divider: true },
      ],
    };
  },
  mounted() {
    this.headers.forEach((h) => {
      if (h.align == undefined) {
        switch (h.type) {
          case HeaderTypes.Money:
          case HeaderTypes.Decimal:
          case HeaderTypes.Quantity:
            h.align = "end";
            break;
          default:
            break;
        }
      }
    });
  },
};
</script>

<style></style>
