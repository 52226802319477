import router from "@/router/routes";
import axios from "axios";
// axios.defaults.withCredentials = true;
axios.defaults.timeout = 15000;

class api {
  static url = "https://api.flyw8.com/";
  static defaultRouteName = "WebLogin";
  static bearer = "";

  static getConfig(params) {
    var prms = {};
    if (params) {
      prms = params;
    }
    if (this.bearer) {
      if (prms.headers) {
        prms.headers = { ...prms.headers, Authorization: "Bearer " + this.bearer };
      } else {
        prms.headers = { Authorization: "Bearer " + this.bearer };
      }
    }
    return prms;
  }

  static uploadImage(prefix, name, file) {
    let data = new FormData();
    data.append("name", name);
    data.append("image", file); //event.target.files[0]);
    let config = this.getConfig({
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    var prom = axios.post(api.url + prefix, data, this.getConfig(config));
    return this.process(prom);
  }

  static get(prefix, config) {
    var prom = axios.get(api.url + prefix, this.getConfig(config));
    return this.process(prom);
  }

  static post(prefix, data, config) {
    var prom = axios.post(api.url + prefix, data, this.getConfig(config));
    return this.process(prom);
  }

  static patch(prefix, data, config) {
    var prom = axios.patch(api.url + prefix, data, this.getConfig(config));
    return this.process(prom, "PATCH");
  }

  static delete(prefix, data, config) {
    var prom = axios.delete(api.url + prefix, data, this.getConfig(config));
    return this.process(prom, "DELETE");
  }

  static process(getOrPost, verb) {
    return new Promise((resolve, reject) => {
      getOrPost
        .then((response, error) => {
          if (error) {
            console.log("getOrPost error");
            console.log(error);
            reject(error.response);
          } else if (response.data) {
            if (response.data.message) {
              reject(response.data.message);
            } else {
              if (verb == "PATCH") {
                resolve(response.data[1]);
              } else {
                resolve(response.data);
              }
            }
          } else {
            console.log("random reject without error???");
            console.log(response);
            reject(response);
          }
        })
        .catch((error) => {
          if (error.code && error.code == "ECONNABORTED" && error.message && error.message.indexOf("timeout") != -1) {
            error.message = "Системата е натоварена, моля опитайте малко по-късно.";
            reject(error);
          } else if (error.response && error.response.status == 401) {
            if (router.currentRoute.name == this.defaultRouteName) {
              if (error.message) error.message = "Грешен потребител или парола!";
              reject(error);
            } else {
              if (error.message) error.message = "Моля влезте в системата!";
              router.push({ name: this.defaultRouteName }).then(() => {
                reject(error);
              });
            }
          } else if (error.response && error.response.data) {
            reject(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }
}

export default api;
