<template>
  <v-row class="ma-0 pa-0" style="height: 100%" justify="center" align="center">
    <div class="flex-grow-1"></div>
    <v-col cols="12" justify="center" align="center">
      <v-form ref="refLogin">
        <v-card class="ma-4 pa-2 elevation-5 justify-center" width="260">
          <v-card-title class="prevent-select justify-center ma-4 pa-0"> Вход в системата </v-card-title>
          <v-card-actions class="prevent-select ma-2 mb-0 pa-0">
            <my-text-field large class="ma-0 pa-0" v-model="username" label="Потребител" :rules="rules_required" :autofocus="!username" @keypress.enter="login()"> </my-text-field>
          </v-card-actions>
          <v-card-actions class="prevent-select ma-2 pa-0">
            <my-text-field large class="ma-0 pa-0" v-model="password" type="password" label="Парола" :rules="rules_password" @keypress.enter="login()" required :autofocus="!!username"> </my-text-field>
          </v-card-actions>
          <v-card-actions class="prevent-select ma-2 pa-0">
            <v-btn @click="login()" block outlined rounded>Влез</v-btn>
          </v-card-actions>
          <v-card-actions class="prevent-select ma-2 pa-0 justify-center">
            <v-btn @click="goToRegistration()" small text color="blue">Регистрация </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
    <div class="flex-grow-1"></div>
  </v-row>
</template>
<script>
import api from "@/js/api";
import { globals } from "@/main";
export default {
  name: "LoginView",
  props: {},
  data() {
    return {
      username: null,
      password: null,
      fail_attempts: 0,
    };
  },
  methods: {
    goToRegistration() {
      this.$router.push({
        name: "Register",
      });
    },
    login() {
      if (!this.$refs.refLogin.validate()) {
        return;
      }

      this.showLoader("Вход в системата...", async () => {
        //TODO loader
        await new Promise((resolve) => setTimeout(resolve, this.fail_attempts * 1000));
        api
          .post("login", {
            username: this.username,
            password: this.password,
          })
          .then((res) => {
            globals.CurrentUser = res;
            this.showMessage("success", `Привет, ${res.FirstName}`);
            api.bearer = res.token;
            if (res.IsAdmin) {
              this.$router.push({
                name: "WebAdmin",
              });
            } else {
              this.$router.push({
                name: "WebUser",
              });
            }
          })
          .catch((error) => {
            api.bearer = null;
            this.fail_attempts++;
            if (error.status == 401) {
              this.showMessage("error", "Грешен потребител или парола");
            } else {
              this.showMessage("error", error.message);
            }
          });
      });
    },
  },
  created() {},
  mounted() {
    if (this.$route.query.username) {
      this.username = this.$route.query.username;
    }
    if (this.$route.query.password) {
      this.password = this.$route.query.password;
    }
  },
};
</script>
