<template>
  <vue-draggable-resizable v-bind="$attrs" v-bind:props="$props" :w="300" :h="244" :z="9999" :resizable="false" style="border: 1px solid white; border-radius: 18px">
    <v-card width="300" height="242" class="ma-0 pa-0 elevation-15" style="border-radius: 18px" justify="center" align="center" transition="scroll-y-transition">
      <v-row @mousedown.prevent class="ma-0 pa-0">
        <my-button x-large class="ma-1 pa-0" :width="91" @mousedown="clicknumpad($event, '7')">7</my-button>
        <my-button x-large class="ma-1 pa-0" :width="91" @mousedown="clicknumpad($event, '8')">8</my-button>
        <my-button x-large class="ma-1 pa-0" :width="91" @mousedown="clicknumpad($event, '9')">9</my-button>
      </v-row>
      <v-row @mousedown.prevent class="ma-0 pa-0">
        <my-button x-large class="ma-1 pa-0" :width="91" @mousedown="clicknumpad($event, '4')">4</my-button>
        <my-button x-large class="ma-1 pa-0" :width="91" @mousedown="clicknumpad($event, '5')">5</my-button>
        <my-button x-large class="ma-1 pa-0" :width="91" @mousedown="clicknumpad($event, '6')">6</my-button>
      </v-row>
      <v-row @mousedown.prevent class="ma-0 pa-0">
        <my-button x-large class="ma-1 pa-0" :width="91" @mousedown="clicknumpad($event, '1')">1</my-button>
        <my-button x-large class="ma-1 pa-0" :width="91" @mousedown="clicknumpad($event, '2')">2</my-button>
        <my-button x-large class="ma-1 pa-0" :width="91" @mousedown="clicknumpad($event, '3')">3</my-button>
      </v-row>
      <v-row @mousedown.prevent class="ma-0 pa-0">
        <my-button x-large class="ma-1 pa-0" :width="91" @mousedown="clicknumpad($event, '0')">7</my-button>
        <my-button x-large lass="ma-1 pa-0" :width="91" @mousedown="clicknumpad($event, 'BACKSPACE')"><v-icon>mdi-arrow-collapse-left</v-icon></my-button>
        <my-button x-large class="ma-1 pa-0" :width="91" @mousedown="clicknumpad($event, 'OK')">OK</my-button>
      </v-row>
    </v-card>
  </vue-draggable-resizable>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  methods: {
    clicknumpad(ev, key) {
      ev.preventDefault();
      this.$emit("keypress", key);
      // if (!document.activeElement) {
      //   return;
      // }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.v-row,
.v-col,
.v-card,
.vue-draggable-resizable {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  nav-index: -1;
}
</style>
