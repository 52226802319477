<template>
  <div style="max-height: 100vh; overflow: hidden">
    <picker-view ref="refSomePicker"></picker-view>
    <v-app-bar app>
      <v-card-title class="mx-4 mylogo" style="background: hwb(24 16% 4%)">flyw8</v-card-title>
      <v-spacer></v-spacer>
      <span style="white-space: pre">Email: </span><a href="mailto: 'help@flyw8.com'">help@flyw8.com</a>
      <!-- <v-img style="position: absolute" class="myflyinglogo" :src="require('../assets/logo.png')" contain width="50" /> -->
    </v-app-bar>
    <v-main app>
      <v-row class="prevent-select ma-0 pa-0 px-8" style="overflow-x: hidden; overflow-y: auto; height: 100%" justify="center" align="center">
        <!-- <v-col cols="12" class="flex-grow-1"> </v-col> -->
        <!-- <v-col cols="12">
      <v-row class="ma-2">
        <v-btn class="ma-2"><v-icon>mdi-check</v-icon>button</v-btn>
        <v-switch class="ma-2" label="switch"></v-switch>
        <v-autocomplete class="ma-2" label="autocomplete">
          autocomplete
        </v-autocomplete>
        <v-text-field class="ma-2" label="text field"></v-text-field>
      </v-row>
    </v-col> -->
        <v-col v-for="(mod, ixx) in modules" :style="getStyle(ixx)" :key="mod.name" @mouseenter="mouseOver(ixx)" @mouseleave="mouseOver(null)" cols="12" lg="3" xl="3" md="3" sm="8" xs="12">
          <v-card justify="center" height="310" elevation="5" class="ma-0 pa-0" @click.stop="mod.fn">
            <v-card-title
              v-if="!mod.available"
              :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'transform: translateX(-50%) translateY(-50%) rotate(-45deg)' : 'transform: translateX(-50%) translateY(-50%) rotate(-55deg)'"
              style="justify-content: center; padding: 5px; border-radius: 50px; border: 2px solid hwb(24 16% 4%); color: hwb(24 16% 4%); left: 50%; top: 50%; width: 240px; height: 60px; position: absolute; font-size: 20px"
            >
              Очаквайте
            </v-card-title>
            <v-card-actions :style="mod.available ? '' : 'opacity: 30%'" class="ma-0 justify-center">
              <div style="min-width: 220px">
                <v-card-title class="justify-center">{{ mod.name }}</v-card-title>
                <v-card-subtitle class="ma-0 mb-2" align="center">
                  <v-icon x-large color="primary">{{ mod.icon }}</v-icon>
                </v-card-subtitle>
                <v-card-text class="ma-0 pa-1" style="display: flex" v-for="(op, idx) in mod.operations" :key="idx">
                  <v-icon color="success" class="pr-2">mdi-check</v-icon>
                  <span>{{ op }}</span>
                </v-card-text>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" lg="12" xl="12" md="12" sm="8" xs="12" @mouseenter="mouseOver(-1)" @mouseleave="mouseOver(null)" :style="getStyle(-1)">
          <v-card class="ma-0 pa-0" min-height="175" elevation="5" @click.stop="OpenBackoffice()">
            <v-card-title
              :style="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'transform: translateX(-50%) translateY(-50%) rotate(-45deg)' : 'transform: translateX(-50%) translateY(-50%) rotate(-30deg)'"
              style="justify-content: center; padding: 5px; border-radius: 50px; border: 2px solid hwb(24 16% 4%); color: hwb(24 16% 4%); left: 50%; top: 50%; width: 240px; height: 60px; position: absolute; font-size: 20px"
            >
              Очаквайте
            </v-card-title>
            <v-row class="align-center justify-center" style="opacity: 30%">
              <v-col cols="12" lg="4" xl="4" md="4" sm="10" xs="12" align="center">
                <div class="ma-0" style="min-width: 240px">
                  <v-card-title class="justify-center">Backoffice</v-card-title>
                  <v-card-subtitle class="ma-0 mb-2" align="center">
                    <v-icon x-large color="primary">mdi-warehouse</v-icon>
                  </v-card-subtitle>
                </div>
              </v-col>
              <v-col class="ma-0 pa-0" cols="12" lg="4" xl="4" md="4" sm="10" xs="12" align="center">
                <v-card-actions class="justify-center ma-0 pa-0">
                  <div class="ma-0" style="min-width: 220px">
                    <v-card-text class="ma-0 pa-1" style="display: flex">
                      <v-icon color="success" class="pr-2">mdi-check</v-icon>
                      <span>Заявки, доставки, изписвания</span>
                    </v-card-text>
                    <v-card-text class="ma-0 pa-1" style="display: flex">
                      <v-icon color="success" class="pr-2">mdi-check</v-icon>
                      <span>Инвентаризация</span>
                    </v-card-text>
                    <v-card-text class="ma-0 pa-1" style="display: flex">
                      <v-icon color="success" class="pr-2">mdi-check</v-icon>
                      <span>Оферти</span>
                    </v-card-text>
                    <v-card-text class="ma-0 pa-1" style="display: flex">
                      <v-icon color="success" class="pr-2">mdi-check</v-icon>
                      <span>Издаване на документи</span>
                    </v-card-text>
                  </div>
                </v-card-actions>
              </v-col>
              <v-col class="ma-0 pa-0" cols="12" lg="4" xl="4" md="4" sm="10" xs="12" align="center">
                <v-card-actions class="justify-center ma-0 pa-0">
                  <div class="ma-0 mb-2" style="min-width: 240px">
                    <v-card-text class="ma-0 pa-1" style="display: flex">
                      <v-icon color="success" class="pr-2">mdi-check</v-icon>
                      <span>Изготвяне на справки</span>
                    </v-card-text>
                    <v-card-text class="ma-0 pa-1" style="display: flex">
                      <v-icon color="success" class="pr-2">mdi-check</v-icon>
                      <span>Ценообразуване</span>
                    </v-card-text>
                    <v-card-text class="ma-0 pa-1" style="display: flex">
                      <v-icon color="success" class="pr-2">mdi-check</v-icon>
                      <span>Контрол на персонала</span>
                    </v-card-text>
                  </div>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <!-- <v-col cols="12" class="flex-grow-1"></v-col> -->
      </v-row>
    </v-main>
  </div>
</template>

<script>
import PickerView from "@/components/PickerView.vue";
// import axios from "axios";
export default {
  components: { PickerView },
  name: "StartUp",
  props: {},
  data() {
    return {
      activeModuleIx: null,
      modules: [
        {
          name: "Menu",
          icon: "mdi-book-open-page-variant",
          fn: () => {
            this.OpenMenu();
          },
          available: true,
          operations: ["Достъпно чрез QR код", "Избор на артикули", "Проверка на алергени", "Изчисляване на сметка"],
        },
        {
          name: "Bar",
          icon: "mdi-food",
          fn: () => {
            this.OpenBar();
          },
          available: false,
          operations: ["Бар", "Ресторант", "Кафене", "Сладкарница"],
        },
        {
          name: "Shop",
          icon: "mdi-store",
          fn: () => {
            this.OpenShop();
          },
          available: false,
          operations: ["Магазин за облекло", "Хранителни стоки", "Офис техника", "Детски играчки"],
        },
        {
          name: "Service",
          icon: "mdi-screwdriver",
          fn: () => {
            this.OpenService();
          },
          available: false,
          operations: ["Ремонт на офис техника", "Ремонт на електроуреди", "Ремонт на GSM апарати"],
        },
      ],
    };
  },
  computed: {},
  methods: {
    getStyle(ix) {
      if (ix == this.activeModuleIx) {
        return "transition: all .2s ease-in-out; transform: scale(1.05);";
      } else {
        // var diff = Math.abs(ix - this.activeModuleIx);
        // var cf = 5;
        // var vf = 1 - diff / cf;
        // var mv = Math.pow(10, diff - 1) * vf;
        // if (ix > this.activeModuleIx) mv *= -1;
        // return "transition: all .2s ease-in-out; transform: translateX(" + mv + "%) scale(" + vf + ") ";
        return "transition: all .2s ease-in-out;";
      }
    },
    mouseOver(elemId) {
      if (this.activeModuleIx != elemId) {
        this.activeModuleIx = elemId;
      }
      // while (this.activeModuleIx != elemId) {
      //   var c = 1;
      //   if (this.activeModuleIx > elemId) {
      //     c = -1;
      //   }
      //   this.$nextTick(() => {
      //     this.activeModuleIx += c;
      //   });
      // }
    },
    OpenMenu() {
      this.$router.push({
        name: "WebLogin",
      });
    },
    OpenShop() {
      // this.$router.push({
      //   name: "Login",
      //   params: { moduleName: "OpenShop" },
      // });
    },
    OpenService() {
      // axios({
      //   method: "get",
      //   url: "http://flyw8.com/api/check",
      //   withCredentials: false,
      //   // params: {
      //   //   access_token: SECRET_TOKEN,
      //   // },
      // });
      // var arr = [
      //   {
      //     name: "kifte",
      //     toString: function () {
      //       return this.name;
      //     },
      //   },
      //   { name: "kibapche" },
      // ];
      // this.$refs.refSomePicker.pick("тестваме избор на обект", arr, null, (item) => {
      //   alert(item);
      // });
    },
    OpenBar() {
      // this.showLoader("kifte", () => {
      //   setTimeout(() => {
      //     throw new Error("gre[ka]");
      //   }, 3000);
      // });
      // let loader = this.$loading.show(
      //   {},
      //   {
      //     before: (h) => h("<span>title</span>"),
      //   }
      // );
      // setTimeout(() => {
      //   loader.hide();
      // }, 5000);
    },
    OpenBackoffice() {
      // this.$router.push({
      //   name: "BackOffice",
      // });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.v-card:hover {
  cursor: pointer;
}

.myflyinglogo {
  bottom: 15%;
  position: absolute;
  -webkit-animation: linear infinite;
  -webkit-animation-name: run;
  -webkit-animation-duration: 2s;
  -webkit-animation-delay: 6s;

  animation: linear infinite;
  animation-name: run;
  animation-duration: 2s;
  animation-delay: 6s;
}

@-webkit-keyframes run {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

@keyframes run {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}

/* .v-btn {
  color: var(--v-primary-base);
} */
/* * {
  color: #00203f;
} */
</style>
