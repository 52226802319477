<template>
  <v-row class="ma-0 pa-0" style="height: 100%" justify="center" align="center">
    <div class="flex-grow-1"></div>
    <v-col cols="12" justify="center" align="center">
      <v-form ref="refLogin">
        <v-card class="ma-4 pa-2 elevation-5 justify-center" width="260">
          <v-card-title class="prevent-select justify-center ma-2 pa-0">
            {{ moduleName }}
          </v-card-title>
          <v-card-actions class="prevent-select ma-2 pa-0">
            <my-text-field large class="ma-2 pa-0" v-model="password" type="password" label="Парола" :keyboardVisible="true" :keyboardDraggable="false" :keyboardX="-40" :keyboardY="10" @KeyboardOK="login()" :rules="rules_password" required> </my-text-field>
          </v-card-actions>
          <v-card-actions class="prevent-select ma-2 pa-0">
            <v-btn @click="login()" block outlined rounded>Вход</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
    <div class="flex-grow-1"></div>
  </v-row>
</template>
<script>
export default {
  name: "LoginView",

  props: {
    moduleName: String,
  },
  data() {
    return {
      password: null,
      fail_attempts: 0,
    };
  },
  methods: {
    login() {
      if (!this.$refs.refLogin.validate()) {
        return;
      }
      console.log(this.password);
      console.log("login");
      //TODO post, if fail = fail_attempts++
    },
  },
  created() {},
  mounted() {
    // if (this.$route.query.username) {
    // }
    // if (this.$route.query.password) {
    // }
  },
};
</script>
