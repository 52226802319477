<template>
  <loading v-model="isShown" :active.sync="isShown" :height="80" :opacity="0.85" :zIndex="999999" :background-color="$vuetify.theme.dark ? '#000' : '#FFF'" :color="$vuetify.theme.dark ? '#FFF' : '#000'" loader="dots">
    <v-card-title class="ma-0 pa-0 justify-center" slot="before">{{ title }}</v-card-title>
  </loading>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
  },
  // props: {
  //   canCancel: { type: Boolean, default: false },
  //   fullPage: { type: Boolean, default: true },
  // },
  data() {
    return {
      isShown: false,
      title: "Моля, изчакайте",
      task: null,
      // isLoading: this.value,
    };
  },
  methods: {
    hide() {
      this.isShown = false;
    },
    show(title, task) {
      this.task = task;
      if (title) this.title = title;
      this.isShown = true;
      this.task();

      // return new Promise((resolve) => {
      //   this.task = task;
      //   if (title) this.title = title;
      //   this.isShown = true;
      //   task(resolve);
      // })
      //   .catch((err) => {
      //     console.log("err");
      //     err();r
      //     this.isShown = false;
      //   })
      //   .then(() => {
      //     console.log("then");
      //     this.isShown = false;
      //   });
    },
  },
  created() {
    // console.log("created");
    // if (!this.$loading) {
    //   this.$loading = this;
    // }
  },
};
</script>
<style scped>
.vld-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
