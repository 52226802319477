<template>
  <v-card class="ma-2 pa-2" :max-width="400">
    <v-card-actions>
      <v-col>
        <my-text-field label="Име на обекта / Заглавие на менюто" v-model="StoreName" autofocus></my-text-field>
        <my-text-field label="Телефон за заявки" v-model="StorePhone"></my-text-field>
        <my-button @click="saveSettings()">Запази</my-button>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import { globals } from "@/main";
import api from "@/js/api";
export default {
  data() {
    return {
      StoreName: globals.CurrentUser.StoreName,
      StorePhone: globals.CurrentUser.StorePhone,
    };
  },
  methods: {
    saveSettings() {
      this.showLoader("Записване...", async () => {
        var o = { StoreName: this.StoreName, StorePhone: this.StorePhone };
        api
          .patch("users/settings", o)
          .then(() => {
            globals.CurrentUser.StoreName = this.StoreName;
            globals.CurrentUser.StorePhone = this.StorePhone;
            this.showMessage("info", "Успешен запис на настройки.");
          })
          .catch((error) => {
            this.StoreName = globals.CurrentUser.StoreName;
            this.StorePhone = globals.CurrentUser.StorePhone;
            this.showMessage("error", "Грешка при запис на настройки!\n" + error.message);
          });
      });
    },
  },
};
</script>

<style></style>
