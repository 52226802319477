export default [
  // { Number: 0, Text: "Без алергени", Color: "#ffffff" },
  { Number: 1, Text: "1. Зърнени култури, съдържащи глутен", Color: "#d88e4a" },
  { Number: 2, Text: "2. Ракообразни и продукти от тях", Color: "#22c2f3" },
  { Number: 3, Text: "3. Яйца и продукти от тях", Color: "#fdb957" },
  { Number: 4, Text: "4. Риба и рибни продукти", Color: "#23408e" },
  { Number: 5, Text: "5. Фъстъци и продукти от тях", Color: "#bb9161" },
  { Number: 6, Text: "6. Соя и соеви продукти", Color: "#3dae48" },
  { Number: 7, Text: "7. Мляко и млечни продукти", Color: "#7b5f3d" },
  { Number: 8, Text: "8. Ядки", Color: "#d04c3d" },
  { Number: 9, Text: "9. Целина и продукти от нея", Color: "#a2cc3a" },
  { Number: 10, Text: "10. Синап и продукти от него", Color: "#bbb66e" },
  { Number: 11, Text: "11. Сусамово семе и продукти от него", Color: "#bdb7a1" },
  { Number: 12, Text: "12. Лупина и продукти от нея", Color: "#b1e3fb" },
  { Number: 13, Text: "13. Серен диоксид и сулфиди", Color: "#ffd74a" },
  { Number: 14, Text: "14. Мекотели и продукти от тях", Color: "#844d81" },
];
