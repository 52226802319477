<template>
  <v-overlay v-model="isShown" z-index="99999">
    <v-col style="max-width: 80%" class="ma-2 pa-2" align="center" justify="center">
      <v-alert :type="type" width="auto" :min-width="isSmall ? 320 : 400" prominent rounded>
        <v-col style="max-width: 100%" align="start">
          <span style="text-align: start; max-width: 100%; white-space: pre-line">{{ text }} </span>
        </v-col>
        <v-col align="center">
          <v-row justify="end">
            <my-button large width="auto" @click="hide()">ОК</my-button>
          </v-row>
        </v-col>
      </v-alert>
    </v-col>
  </v-overlay>
</template>

<script>
export default {
  props: {
    ptype: { type: String, default: "success" },
    ptext: { type: String, default: "message" },
  },
  data() {
    return {
      type: this.ptype,
      text: this.ptext,
      isShown: false,
    };
  },
  methods: {
    show(type, text) {
      this.type = type;
      this.text = text;
      this.isShown = true;
    },
    hide() {
      this.isShown = false;
    },
  },
  mounted() {},
};
</script>

<style></style>
