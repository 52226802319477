<template>
  <div>
    <v-btn v-bind="$attrs" block outlined rounded v-on="$listeners" v-bind:props="$props">
      <slot></slot>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {},
};
</script>
