<template>
  <div style="height: calc(100% - 1px); width: 100%; max-width: 100%">
    <v-navigation-drawer app :permanent="!isSmall" :temporary="isSmall">
      <v-list-item class="prevent-select">{{ CurrentUser.FirstName + " " + CurrentUser.LastName }} </v-list-item>
      <v-divider></v-divider>
      <v-list class="ma-1">
        <v-list-item class="prevent-select mb-2" :disabled="!user" @click="approveUser">Одобри потребител</v-list-item>
        <v-list-item class="prevent-select mb-2" :disabled="!user" @click="openUserMenu">Отвори менюто</v-list-item>
      </v-list>
      <!-- <v-card-title class="ma-0 pa-0">Потребители</v-card-title> -->
    </v-navigation-drawer>
    <v-main app>
      <my-grid :items="users" :headers="headers" @rowchange="setUser" class="ma-2 pa-0"></my-grid>
    </v-main>
  </div>
</template>

<script>
import api from "@/js/api";
import { globals, header_types } from "@/main";
export default {
  data() {
    return {
      CurrentUser: globals.CurrentUser,
      user: null,
      users: [],
      headers: [
        // {
        //   text: "Потребителско име",
        //   align: "start",
        //   sortable: false,
        //   value: "name",
        //   divider: true,
        // },
        { text: "Админ", value: "IsAdmin", divider: true, align: "center", type: header_types.bool },
        { text: "Одобрен", value: "IsApproved", divider: true, align: "center", type: header_types.bool },
        { text: "Потребител", value: "UserName", divider: true },
        { text: "Име", value: "FirstName", divider: true },
        { text: "Презиме", value: "MiddleName", divider: true },
        { text: "Фамилия", value: "LastName", divider: true },
        { text: "Телефон", value: "Phone", divider: true, type: header_types.phone, click: this.phoneCall },
        { text: "E-mail", value: "Email" },
      ],
    };
  },
  methods: {
    openUserMenu() {
      window.open("https://flyw8.com/menu/" + this.user.UserName, "_blank");
    },
    approveUser() {
      if (this.user) {
        if (this.user.IsApproved == 1) {
          this.showMessage("info", "Потребителят е вече одобрен.");
        } else {
          this.showLoader("Вход в системата...", async () => {
            api
              .patch("users/approve", this.user)
              .then(() => {
                this.loadData();
                this.showMessage("info", "Потребителят е одобрен.");
              })
              .catch((error) => {
                this.showMessage("error", "Грешка при одобряване на потребител!\n" + error.message);
              });
          });
        }
      }
    },
    setUser(u) {
      this.user = u;
    },
    updateUser(u) {
      var ix = this.users.indexOf(this.users.find((uu) => uu.ID == u.ID));
      this.users[ix] = u;
    },
    loadData() {
      api
        .get("users")
        .then((res) => {
          this.users = res;
        })
        .catch((error) => {
          this.showMessage("error", "Грешка при четене на потребители!\n" + error.message);
        });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.v-list >>> .v-list-item {
  background-color: lightblue;
  border-radius: 25px;
  cursor: pointer;
}
</style>
