<template>
  <div ref="myGridContainer" id="my-grid-container" class="prevent-select ma-0 pa-0" style="overflow-x: auto; overflow-y: hidden; height: 100%; min-height: 100%; max-height: 100%" justify="center" align="center">
    <v-data-table
      ref="myGrid"
      class="prevent-select"
      id="my-grid"
      :height="gridHeight"
      :headers="headers"
      :header-props="headerProps"
      :items="visibleItems"
      v-model="currentRows"
      v-columns-resizable
      locale="bg-BG"
      item-key="ID"
      single-select
      fixed-header
      :mobile-breakpoint="0"
      dense
      style="width: 100%"
      no-data-text="Няма нищо :("
      disable-pagination
      hide-default-footer
      :loading="loading"
      loading-text="Зареждане..."
      @click:row="currentRowClicked"
    >
      <template v-if="firstVisibleRow > 0" v-slot:[`body.prepend`]>
        <tr :style="'height:' + startHeight + 'px'">
          <td :colspan="headers.length"></td>
        </tr>
      </template>

      <!-- <template v-for="(h, index) in headers" v-slot:[`header.${h.value}`]="{ header }">
        <div :key="'th' + index" style="display: inline-block; width: 100%; text-align: center; align-items: center">
          <span style="width: 100%">{{ header.text }}</span>
          <v-divider vertical></v-divider>
        </div>
      </template> -->

      <template v-for="(h, index) in headers" v-slot:[`item.${h.value}`]="{ item }">
        <div :key="index" style="display: inline-block; padding-top: 0; padding-bottom: 0; width: 100%" :style="h.click ? 'cursor: pointer' : ''" @click="h.click ? h.click(item[h.value]) : ''">
          <div v-if="h.type == headertypes.phone">
            <v-icon small class="mr-3">mdi-phone</v-icon>
            <span style="text-decoration: underline">
              {{ item[h.value] }}
            </span>
          </div>
          <div v-else-if="h.type == headertypes.bool" style="display: inline-block" align-items="center">
            <v-simple-checkbox class="ma-0 pa-0" style="align-self: end" readonly dense hide-details :value="!!item[h.value]"></v-simple-checkbox>
          </div>
          <span v-else-if="h.type == headertypes.money" :key="index">
            {{ item[h.value] ? item[h.value].toFixed(2) : "" }}
          </span>
          <span v-else-if="h.type == headertypes.array_of_objects" :key="index">
            {{ item[h.value] ? h.items.data.find((d) => d[h.items.value] == item[h.value])[h.items.text] : "" }}
          </span>
          <!-- <v-img v-else-if="h.type == headertypes.image" :key="index" :width="rowHeight" :height="rowHeight" contain :src="item[h.value]"> </v-img> -->
          <span v-else :key="index">
            {{ item[h.value] }}
          </span>
        </div>
      </template>

      <!-- <template v-slot:item="{ item, expand, isExpanded }">
        <tr>
          <td>
            <v-btn @click="expand(!isExpanded)">Expand</v-btn>
          </td>
          <template v-for="(h, hidx) in headers">
            <td :key="hidx" class="d-block d-sm-table-cell">
              <span :id="hidx"> </span>
              {{ item[field] }}
            </td>
          </template>
        </tr>
      </template> -->
      <template v-if="firstVisibleRow + visibleRows < this.items.length" v-slot:[`body.append`]>
        <tr>
          <td :colspan="headers.length" :style="'padding-top:' + endHeight + 'px'"></td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array },
    headers: { type: Array },
  },
  data: () => ({
    headerProps: {
      sortByText: "Сортирай по",
    },
    loading: false,
    currentRow: null,
    currentRows: [],
    grid: null,
    gridHeight: 350,
    wrapper: null,
    debounceTimeout: null,
    rowHeight: 32,
    firstVisibleRow: 0,
    visibleRows: 25,
  }),
  methods: {
    getImageUrl(img) {
      // console.log("img");
      // console.log(img);
      img ? new URL(img).toString() : "";
    },
    currentRowClicked(item, row) {
      this.$nextTick(() => {
        if (this.currentRow != item) {
          this.currentRow = item;
        } else {
          this.currentRow = null;
        }
        row.select(this.currentRow);
        this.$emit("rowchange", this.currentRow);
      });
    },

    onScroll(e) {
      // this.wrapper.classList.add("blur_effect");
      this.debounceTimeout && clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        const { scrollTop } = e.target;
        const totalRows = Math.ceil(scrollTop / this.rowHeight);
        this.firstVisibleRow = totalRows + this.visibleRows > this.items.length ? this.items.length - this.visibleRows : totalRows;
        // this.wrapper.classList.remove("blur_effect");
        this.$nextTick(() => {
          e.target.scrollTop = scrollTop;
        });
      }, 20);
    },
    gridResize() {
      this.$nextTick(() => {
        this.grid = this.$refs.myGrid;
        this.gridHeight = this.$refs.myGridContainer.clientHeight - 1;
        this.visibleRows = Math.ceil(this.gridHeight / this.rowHeight);
        this.$nextTick(() => {
          this.wrapper = this.$refs.myGrid.$el.children[0]; //querySelector("v-data-table__wrapper");
          if (this.wrapper) {
            this.wrapper.addEventListener("scroll", this.onScroll);
          }
        });
      });
    },
  },
  computed: {
    visibleItems() {
      return this.items.slice(this.firstVisibleRow, this.visibleRows + this.firstVisibleRow);
    },
    startHeight() {
      return this.firstVisibleRow * this.rowHeight;
    },
    endHeight() {
      return this.rowHeight * (this.items.length - this.firstVisibleRow);
    },
  },
  mounted() {
    this.gridResize();
  },
  destroyed() {
    this.wrapper.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style>
/* #virtual-scroll-table {
  max-height: 400px;
} */
.theme--light.v-data-table tbody tr.v-data-table__selected {
  background: lightblue !important;
}
.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: gray !important;
}
</style>
