<template>
  <v-tooltip v-model="showMe" top>
    <!-- <v-tooltip @click.self="showMe = false" top :open-on-click="true" :open-on-focus="false" :open-on-hover="false" v-model="showMe"></v-tooltip> -->
    <template v-slot:activator="{ on }">
      <v-row @click="showMe = false" v-bind="$attrs" v-on="on" class="ma-0 pa-0" align="center" style="cursor: pointer" :style="vertical ? 'max-width: 18px;' : ''">
        <v-card-subtitle v-if="showTitle" class="ma-0 pa-0 mr-3" align="start" style="font-size: 12px">{{ "Алергени:" }}</v-card-subtitle>
        <span v-if="showTitle && alergens && alergens.length == 0" style="font-size: 12px">Няма</span>
        <template v-else>
          <v-card v-for="a in alergens" :key="a" :width="15" :height="15" class="prevent-select ma-0 pa-0 mr-1 mb-1" :style="'font-size: 10px; background: ' + getAlergen(a).Color" rounded x-small outlined>{{ getAlergen(a).Number }}</v-card>
        </template>
      </v-row>
    </template>
    <v-col>
      <v-row><span>Алергени:</span></v-row>
      <v-row :key="aa" v-for="aa in alergens">
        <v-card :width="15" :height="15" class="pa-0 prevent-select mr-1" :style="'background: ' + getAlergen(aa).Color" rounded x-small outlined></v-card>
        <span style="white-space: pre-line">{{ getAlergen(aa).Text }}</span>
      </v-row>
    </v-col>
  </v-tooltip>
</template>

<script>
import Alergens from "@/entities/Alergens";
export default {
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    alergens: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showMe: false,
      alergensList: Alergens,
    };
  },
  methods: {
    getAlergen(num) {
      return this.alergensList.find((i) => i.Number == num);
    },
  },
};
</script>

<style></style>
