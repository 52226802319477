<template>
  <v-app>
    <!-- <v-main app >
      <transition>
        <keep-alive> -->
    <loading-view ref="refLoader"></loading-view>
    <message-view ref="refMessage"></message-view>
    <edit-view ref="refEditor"></edit-view>
    <image-view ref="refImageView"></image-view>
    <yes-no-dialog ref="refYesNoDialog"></yes-no-dialog>
    <router-view class="ma-0 pa-0 d-flex approuter"> </router-view>

    <!-- </keep-alive>
      </transition>
    </v-main> -->
  </v-app>
</template>
<script>
import LoadingView from "./components/LoadingView.vue";
import MessageView from "./components/MessageView.vue";
import EditView from "./components/EditView.vue";
import YesNoDialog from "./components/YesNoDialog.vue";
import ImageView from "./components/ImageView.vue";

export default {
  components: { LoadingView, MessageView, EditView, YesNoDialog, ImageView },
  name: "App",
  data: () => ({}),
  methods: {},
  created() {},
};
</script>

<style>
html {
  overflow-y: hidden;
}

.approuter {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.blur_effect {
  filter: blur(5px);
}

.mylogo {
  justify-content: center;
  font-size: 24px;
  rotate: -20deg;
  transform-origin: 40px;
  border-radius: 55px;
  background: hwb(24 16% 4%);
  padding: 5px;
  width: 80px;
  height: 40px;
}

.custom-icon {
  fill: currentColor;
}

.custom-icon-background {
  background: currentColor;
}
</style>
