<template>
  <v-overlay :value="isShown" :dark="$vuetify.theme.dark">
    <v-card class="ma-2 pa-0 elevation-15" width="600" height="500">
      <v-card-title class="subtitle-1">{{ title }}</v-card-title>
      <v-divider></v-divider>
      <v-virtual-scroll :items="itemz" :height="380" :item-height="itemHeight">
        <template v-slot:default="{ item }">
          <v-card width="auto" :height="itemHeight - 8" @click="selected = item" :style="selected == item ? 'font-weight: bold;' : ''">
            <v-card-actions> {{ stringFn(item) }}</v-card-actions>
          </v-card>
        </template>
      </v-virtual-scroll>
      <v-divider></v-divider>
      <v-card-actions style="height: 60px">
        <v-row class="mx-1">
          <v-spacer></v-spacer>
          <my-button :width="150" @click="close(true)">ОК</my-button>
          <v-spacer></v-spacer>
          <my-button :width="150" @click="close(false)">Отказ</my-button>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
import MyButton from "./MyButton.vue";
export default {
  components: { MyButton },

  data() {
    return {
      title: "Избор",
      itemHeight: 40,
      selected: null,
      itemz: [],
      callback: null,
      isShown: false,
      stringFn: null,
    };
  },
  computed: {},
  methods: {
    async pick(title, items, stringFn, callback) {
      return new Promise((resolve) => {
        if (title) this.title = title;
        this.itemz = items;
        if (!stringFn) {
          stringFn = function (item) {
            return item.toString();
          };
        }
        this.stringFn = stringFn;
        this.callback = callback;
        this.isShown = true;
        resolve();
      });
    },
    async close(confirm) {
      if (confirm && !this.selected) {
        //TODO
        alert("не сте избрали нищо!");
      } else {
        new Promise((resolve) => {
          if (this.callback) {
            this.callback(confirm ? this.selected : null);
          }
          resolve();
        });
        this.isShown = false;
      }
    },
  },
  mounted() {},
};
</script>

<style></style>
