<template>
  <div style="width: 100%; height: 100">
    <v-text-field v-bind="$attrs" v-bind:props="$props" v-on="$listeners" :value="value" @change="onChange" @input="onInput"> </v-text-field>
    <my-keyboard @keypress="kbdPress" :x="keyboardX" :y="keyboardY" :draggable="keyboardDraggable" :parent="true" v-if="GG.showKeyboard && keyboardVisible"></my-keyboard>
  </div>
</template>
<script>
import MyKeyboard from "@/components/MyKeyboard.vue";
export default {
  components: {
    MyKeyboard,
  },
  props: {
    value: { type: [String, Number], default: null },
    keyboardVisible: { type: Boolean, default: false },
    keyboardDraggable: { type: Boolean, default: false },
    keyboardX: { type: Number, default: 0 },
    keyboardY: { type: Number, default: 0 },
  },

  emits: ["update:value"],

  data() {
    return {
      textFieldValue: this.value,
    };
  },
  computed: {
    getValue() {
      return this.textFieldValue;
    },
  },
  methods: {
    onInput() {
      this.$emit("input", this.textFieldValue);
    },
    onChange() {
      this.$emit("change", this.textFieldValue);
    },
    kbdPress(key) {
      switch (key) {
        case "0":
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
          if (!this.textFieldValue) this.textFieldValue = key;
          else this.textFieldValue += key;
          break;
        case "BACKSPACE":
          if (this.textFieldValue && this.textFieldValue.length > 0) {
            this.textFieldValue = this.textFieldValue.substring(0, this.textFieldValue.length - 1);
          }
          break;
        case "OK":
          this.$emit("KeyboardOK");
          break;
        default:
          break;
      }
    },
  },
  watch: {
    // textFieldValue() {
    //   this.onInput();
    // },
  },
  mounted() {},
};
</script>
