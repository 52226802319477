<template>
  <v-row class="ma-0 pa-0" style="max-height: 100vh; overflow-y: auto" justify="center" align="center">
    <div class="flex-grow-1"></div>
    <v-row justify="center" align="center">
      <v-form ref="refLogin">
        <v-card :width="isSmall ? 300 : 600" align-center class="prevent-select ma-2 pa-2 elevation-5 justify-center">
          <v-card-title class="prevent-select justify-center ma-4 pa-0"> Регистрация </v-card-title>
          <v-row class="ma-0 pa-0 align-center justify-center">
            <v-col class="ma-0 pa-0" cols="12" xl="6" md="6" lg="6" sm="12" justify="center" align="center">
              <v-card-actions class="ma-0 mx-2 pa-0">
                <my-text-field width="100%" v-model="username" label="Потребител" autofocus :rules="rules_required" required> </my-text-field>
              </v-card-actions>
              <v-card-actions class="ma-0 mx-2 pa-0">
                <my-text-field v-model="password" type="password" label="Парола" :rules="rules_password" required> </my-text-field>
              </v-card-actions>
              <v-card-actions class="ma-0 mx-2 pa-0">
                <my-text-field v-model="confirm_password" type="password" label="Повтори паролата" :rules="rules_password" required> </my-text-field>
              </v-card-actions>
              <v-card-actions class="ma-0 mx-2 pa-0">
                <my-text-field v-model="phone" label="Телефон за връзка" :rules="rules_required" required> </my-text-field>
              </v-card-actions>
            </v-col>
            <v-col class="ma-0 pa-0" cols="12" xl="6" md="6" lg="6" sm="12" justify="center" align="center">
              <v-card-actions class="ma-0 mx-2 pa-0">
                <my-text-field v-model="firstName" label="Име" :rules="rules_required" required> </my-text-field>
              </v-card-actions>
              <v-card-actions class="ma-0 mx-2 pa-0">
                <my-text-field v-model="middleName" label="Презиме" :rules="rules_required" required> </my-text-field>
              </v-card-actions>
              <v-card-actions class="ma-0 mx-2 pa-0">
                <my-text-field v-model="lastName" label="Фамилия" :rules="rules_required" required> </my-text-field>
              </v-card-actions>
              <v-card-actions class="ma-0 mx-2 pa-0">
                <my-text-field v-model="email" label="E-mail" :rules="[...rules_required, ...rules_email]" required> </my-text-field>
              </v-card-actions>
            </v-col>
            <v-card-actions class="ma-4 pa-0 prevent-select">
              <v-btn @click="register()" block outlined rounded>Регистрирай ме</v-btn>
            </v-card-actions>
          </v-row>
        </v-card>
      </v-form>
    </v-row>
    <div class="flex-grow-1"></div>
  </v-row>
</template>

<script>
import api from "@/js/api";
export default {
  props: {},
  data() {
    return {
      forbiddden_usernames: ["login", "weblogin", "webadmin", "register", "backoffice", "reports", "select", "update", "delete"],
      username: "",
      password: "",
      confirm_password: "",
      firstName: "",
      middleName: "",
      lastName: "",
      phone: "",
      email: "",
      fail_attempts: 0,
    };
  },
  methods: {
    register() {
      if (!this.$refs.refLogin.validate()) {
        return;
      }
      if (this.password != this.confirm_password) {
        this.showMessage("error", "Паролата не съвпада!");
      } else if (this.forbiddden_usernames.indexOf(this.username.toLowerCase()) != -1) {
        this.showMessage("error", "Невалидно потребителско име!");
      } else {
        this.showLoader("Регистрация на потребител...", async () => {
          await new Promise((resolve) => setTimeout(resolve, this.fail_attempts * 1000));
          api
            .post("register", {
              username: this.username,
              password: this.password,
              firstName: this.firstName,
              middleName: this.middleName,
              lastName: this.lastName,
              phone: this.phone,
              email: this.email,
            })
            .then(() => {
              this.showMessage("success", "Успешна регистрация.");
              setTimeout(() => {
                this.$router.push({
                  name: "WebLogin",
                  query: { username: this.username },
                });
              }, 222);
            })
            .catch((error) => {
              this.fail_attempts++;
              this.showMessage("error", error.message);
            });
        });
      }
    },
  },
};
</script>

<style></style>
