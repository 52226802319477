<template>
  <v-dialog v-model="isShown" z-index="99999" persistent :width="imgWidth + 20">
    <v-card :style="'max-width: ' + (imgWidth + 20) + 'px'" class="ma-0 pa-2" align="center" justify="center">
      <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>

      <video v-if="isCameraOpen" ref="refCamera" :width="imgWidth" :height="imgHeight" autoplay></video>
      <canvas v-show="!isCameraOpen" id="photoTaken" ref="refCanvas" style="border: 1px solid blue" :width="imgWidth" :height="imgHeight"></canvas>
      <!-- <v-img v-else class="ma-0 pa-0" style="border: 1px solid blue; cursor: pointer" :src="url" :width="imgWidth" :height="imgHeight" contain></v-img> -->

      <!-- <input ref="refImageFileCamera" style="visibility: hidden; display: none" type="file" accept="image/*" capture="camera" @change="photoTaken" /> -->
      <v-file-input ref="refImageFileInput" style="visibility: hidden; display: none" v-model="file" @change="imageUploaded"> </v-file-input>
      <v-row justify="center" class="ma-0 pa-0">
        <my-button v-if="!isCameraOpen" class="ma-2" :width="140" @click="toggleCamera"><v-icon>mdi-camera</v-icon></my-button>
        <my-button v-else class="ma-2" :width="140" @click="toggleCamera"><v-icon>mdi-camera-off</v-icon></my-button>
        <v-spacer></v-spacer>
        <my-button v-if="isCameraOpen" class="ma-2" :width="140" @click="takePhoto"><v-icon>mdi-camera-iris</v-icon></my-button>
        <my-button v-else class="ma-2" :width="140" @click="chooseFile"><v-icon>mdi-attachment</v-icon></my-button>
      </v-row>
      <v-col align="center" class="ma-0 mt-4 pa-0">
        <v-divider></v-divider>
        <v-row justify="end" class="ma-0 pa-0">
          <my-button v-if="editable" :width="100" class="ma-2" @click="close(true)">ОК</my-button>
          <my-button :width="100" class="ma-2" @click="close(false)">{{ editable ? "Затвори" : "Отказ" }}</my-button>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/js/api";
import { globals } from "@/main";
export default {
  props: {},
  data() {
    return {
      file: null,
      editable: false,
      imageId: null,
      callback: null,
      isShown: false,

      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      imgWidth: 360,
      imgHeight: 270,
    };
  },
  methods: {
    // startCameraFile() {
    //   if (this.editable) this.$refs.refImageFileCamera.click();
    // },
    // photoTaken(e, f) {
    //   this.showMessage("info", f);
    //   this.url = e.target.files[0].toDataURL("image/jpeg");
    // },
    chooseFile() {
      if (this.editable) this.$refs.refImageFileInput.$children[0].$el.click();
    },

    imageUploaded() {
      var url = URL.createObjectURL(this.file);
      this.drawImage(url);
    },

    drawImage(img) {
      const canvas = this.$refs.refCanvas;
      const context = canvas.getContext("2d");
      context.drawImage(img, 0, 0, this.imgWidth, this.imgHeight); // Or at whatever offset you like
      this.canvasToFile();

      // var img = new Image();
      // img.onload = () => {
      //   const canvas = this.$refs.refCanvas;
      //   const context = canvas.getContext("2d");
      //   context.drawImage(img, 0, 0, this.imgWidth, this.imgHeight); // Or at whatever offset you like
      //   this.canvasToFile();
      // };
      // img.setAttribute("crossorigin", "anonymous");
      // img.src = url;
    },

    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    createCameraElement() {
      this.showLoader("Стартиране на камерата...", () => {
        this.isLoading = true;
        const constraints = (window.constraints = {
          audio: false,
          video: true,
        });

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            this.isLoading = false;
            this.$refs.refCamera.srcObject = stream;
            this.hideLoader();
          })
          .catch((error) => {
            this.isLoading = false;
            this.showMessage("error", "Грешка при опит за стартиране на камера!\n" + error);
          });
      });
    },

    stopCameraStream() {
      let tracks = this.$refs.refCamera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;
        const FLASH_TIMEOUT = 50;
        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }
      this.isPhotoTaken = !this.isPhotoTaken;
      const canvas = this.$refs.refCanvas;
      const context = canvas.getContext("2d");
      context.drawImage(this.$refs.refCamera, 0, 0, this.imgWidth, this.imgHeight);
      this.canvasToFile(canvas);
      this.toggleCamera();
    },

    canvasToFile() {
      // this.image = new Image();
      // this.image.src = canvas.toDataURL("image/jpeg");
      const canvas = this.$refs.refCanvas;
      var src = canvas.toDataURL("image/jpeg");
      var blobBin = atob(src.split(",")[1]);
      var array = [];
      for (var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      var blob = new Blob([new Uint8Array(array)], { type: "image/png" });

      // var dataURI = canvas.toDataURL("image/jpeg");
      // var byteString;
      // if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
      // else byteString = unescape(dataURI.split(",")[1]);
      // // separate out the mime component
      // var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // // write the bytes of the string to a typed array
      // var ia = new Uint8Array(byteString.length);
      // for (var i = 0; i < byteString.length; i++) {
      //   ia[i] = byteString.charCodeAt(i);
      // }
      // this.file = new Blob([ia], { type: mimeString });

      // canvas.toBlob((blob) => {
      //   let file = new File([blob], "fileName.jpg", { type: "image/jpeg" });
      // }, "image/jpeg");

      this.file = new File([blob], this.imageId, { type: "image/jpeg" });
    },

    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },

    show(defaultImageUrl, imageId, editable, onCloseCallback) {
      this.imageId = imageId;
      this.editable = editable;
      this.callback = onCloseCallback;
      if (defaultImageUrl) {
        api.get("images/" + globals.CurrentUser.UserName + "/" + this.imageId).then((img) => {
          this.drawImage(img);
        });
      }

      this.isShown = true;
    },
    close(confirm) {
      if (this.isCameraOpen) this.toggleCamera();
      this.callback(confirm ? this.file : undefined);
      this.isShown = false;
    },
  },
  mounted() {},
};
</script>

<style></style>
