<template>
  <div style="height: calc(100% - 2px); width: 100%; max-width: 100%">
    <v-app-bar app :height="menuHeight">
      <v-app-bar-nav-icon v-if="isSmall" @click="showMenu = !showMenu"></v-app-bar-nav-icon>
      <v-btn text class="mx-1 prevent-select" small dense style="width: auto" align="center" :disabled="!menus[currentMenu].canAdd" @click="addOrEdit(true)">
        <v-icon small class="mx-1"> mdi-plus-circle-outline</v-icon>
        <v-list-item-action-text small v-if="!isSmall" class="mr-1"> Добави </v-list-item-action-text>
      </v-btn>
      <v-divider vertical></v-divider>
      <v-btn text class="mx-1 prevent-select" small dense align="center" :disabled="!menus[currentMenu].canEdit()" @click="addOrEdit(false)">
        <v-icon class="mx-1"> mdi-pencil </v-icon>
        <v-list-item-action-text v-if="!isSmall" class="mr-1"> Редактирай </v-list-item-action-text>
      </v-btn>
      <v-divider vertical></v-divider>
      <v-btn text class="mx-1 prevent-select" small dense v-if="currentMenu == 1" align="center" :disabled="!menus[currentMenu].canEdit()" @click="uploadImage()">
        <v-icon class="mx-1"> mdi-camera-enhance-outline </v-icon>
        <v-list-item-action-text v-if="!isSmall" class="mr-1"> Изображение </v-list-item-action-text>
      </v-btn>
      <v-divider vertical v-if="currentMenu == 1"></v-divider>
      <v-btn text class="mx-1 prevent-select" small dense align="center" :disabled="!menus[currentMenu].canEdit()" @click="deleteSelected()">
        <v-icon class="mx-1"> mdi-delete-alert-outline </v-icon>
        <v-list-item-action-text v-if="!isSmall" class="mr-1"> Изтрий </v-list-item-action-text>
      </v-btn>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer app :permanent="!isSmall" :temporary="isSmall" v-model="showMenu">
      <v-list-item class="prevent-select elevation-5">{{ CurrentUser.FirstName + " " + CurrentUser.LastName }} </v-list-item>
      <v-divider></v-divider>
      <v-list expand class="ma-0 pa-0">
        <v-list-item-group v-model="currentMenu">
          <v-list-item v-for="(m, idx) in menus" :key="idx" class="prevent-select" @click="m.clickFn()">
            <v-list-item-title>{{ m.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-img v-if="currentMenu == 1 && currentItem && isValidUrl(currentItem.Image)" class="ma-2 pa-0" style="border: 1px solid lightblue" contain max-width="100%" :src="currentItem.Image" aspect-ratio="1"></v-img>
        <!-- <v-list-item-action align="center" justify="center">
          <qrcode-vue ref="refQR" :value="menuPath" :size="200" level="H" />
        </v-list-item-action> -->
      </v-list>
      <!-- <v-card-title class="ma-0 pa-0">Потребители</v-card-title> -->
    </v-navigation-drawer>

    <v-main app>
      <my-grid v-if="currentMenu == 0" :items="groups" :headers="headersGroups" @rowchange="setCurrentItem"></my-grid>
      <my-grid v-else-if="currentMenu == 1" :items="items" :headers="headersItems" @rowchange="setCurrentItem"></my-grid>
      <web-settings v-else-if="currentMenu == 2"></web-settings>
    </v-main>
  </div>
</template>

<script>
import api from "@/js/api";
import { globals, header_types } from "@/main";
import Alergens from "@/entities/Alergens";
import WebSettings from "./WebSettings.vue";
// import QrcodeVue from "qrcode.vue";
export default {
  components: {
    WebSettings,
    // QrcodeVue,
  },
  data() {
    return {
      menus: [
        {
          name: "Групи",
          clickFn: () => {
            this.IsShowingGroups = true;
          },
          canAdd: true,
          canEdit: () => {
            return this.currentItemIsNotNull();
          },
        },
        {
          name: "Артикули",
          clickFn: () => {
            this.IsShowingGroups = false;
          },
          canAdd: true,
          canEdit: () => {
            return this.currentItemIsNotNull();
          },
        },
        {
          name: "Настройки",
          clickFn: () => {
            this.openSettings();
          },
          canAdd: false,
          canEdit: () => {
            return false;
          },
        },
        {
          name: "Към менюто",
          clickFn: () => {
            this.openMenu();
          },
          canAdd: false,
          canEdit: () => {
            return false;
          },
        },
      ],
      showMenu: true,
      ShowOperations: true,
      IsShowingGroups: true,
      CurrentUser: globals.CurrentUser,
      editingItem: {},
      currentItem: null,
      currentMenu: 0,
      groups: [],
      headersGroups: [
        // {
        //   text: "Потребителско име",
        //   align: "start",
        //   sortable: false,
        //   value: "name",
        //   divider: true,
        // },
        { text: "Име", value: "Name", divider: true },
      ],
      headersItems: [
        { text: "Име", value: "Name", divider: true },
        { text: "Описание", value: "Description", divider: true },
        { text: "Цена", value: "Price", divider: true, align: "end", type: header_types.money },
        { text: "Група", value: "GroupID", divider: true, type: header_types.array_of_objects, items: { data: this.groups, text: "Name", value: "ID" } },
        { text: "Алергени", value: "Alergens", divider: true, type: header_types.array, items: { data: Alergens, text: "Text", value: "Number", multiple: true } },

        // { text: "Картинка", value: "Image", divider: true, type: header_types.image, readonly: true },
        // { text: "Картинка", value: "Image", divider: true, type: header_types.image, readonly: true },
      ],

      items: [],
    };
  },
  computed: {
    menuPath() {
      return window.location.href.replace(this.$router.currentRoute.path, "/menu/" + this.CurrentUser.UserName);
    },
  },
  methods: {
    currentItemIsNotNull() {
      return !!this.currentItem;
    },
    openSettings() {
      //TODO
    },
    openMenu() {
      window.open(this.menuPath, "_blank");
      // this.$router.push({
      //   name: "Menu",
      //   params: {
      //     username: this.CurrentUser.UserName,
      //   },
      // });
    },
    setCurrentItem(i) {
      this.currentItem = i;
    },
    deleteSelected() {
      this.showYesNoDialog("Сигурни ли сте, че искате да изтриете " + (this.IsShowingGroups ? "групата?" : "артикула?"), (confirm) => {
        if (confirm) {
          this.showLoader("Изтриване...", async () => {
            api.delete(this.IsShowingGroups ? "groups" : "items", this.currentItem).then(() => {
              this.loadData();
            });
          });
        }
      });
    },
    addOrEdit(isNew) {
      this.showEditor(` ${isNew ? "Добавяне" : "Редакция"} на ${this.IsShowingGroups ? "група" : "артикул"}`, isNew ? this.editingItem : this.currentItem, this.IsShowingGroups ? this.headersGroups : this.headersItems, (confirm) => {
        if (confirm) {
          this.showLoader("Обработка...", async () => {
            if (isNew) {
              api
                .post(this.IsShowingGroups ? "groups" : "items", this.editingItem)
                .then((newItem) => {
                  this.items.push(newItem);
                  this.editingItem = {};
                  this.loadData();
                })
                .catch((error) => {
                  this.showMessage("error", `Грешка при добавяне на ${this.IsShowingGroups ? "група!" : "артикул!"}\n` + error.message);
                });
            } else
              api
                .patch(this.IsShowingGroups ? "groups" : "items", this.currentItem)
                .then(() => {
                  this.loadData();
                })
                .catch((error) => {
                  this.showMessage("error", "Грешка при добавяне на артикул!\n" + error.message);
                });
          });
        } else {
          this.editingItem = {};
          // this.showMessage("info", "Операцията е отказана.");
        }
      });
    },
    uploadImage() {
      // var imgUrl = api.url + "image/" + this.CurrentUser.UserName + "/" + item.ID;
      var item = this.currentItem;
      this.showImage(item.Image, item.ID, true, (imageResult) => {
        if (imageResult) {
          api
            .uploadImage("items/images/" + item.ID, item.ID, imageResult)
            .then(() => {
              this.showMessage("info", "Успешна промяна на картинка");
            })
            .catch((error) => {
              this.showMessage("error", "Грешка при качване на картинка!\n" + error.message);
            });
        }
      });
    },
    loadData() {
      this.showLoader("Зареждане на данните...", async () => {
        api
          .get("groups")
          .then((groups) => {
            this.groups = groups;
            this.groups.forEach((g) => {
              g.toString = () => g.LastName;
            });
            this.headersItems.find((h) => h.value == "GroupID").items.data = this.groups;
            api
              .get("items")
              .then((items) => {
                this.items = items;
                try {
                  this.items.forEach((i) => {
                    i.Alergens = JSON.parse(i.Alergens);
                    i.Image = api.url + "images/" + this.CurrentUser.UserName + "/" + i.ID;
                  });
                } catch {
                  /**ignored */
                }
                this.hideLoader();
              })
              .catch((error) => {
                this.showMessage("error", "Грешка при зареждане на артикули!\n" + error.message);
              });
          })
          .catch((error) => {
            this.showMessage("error", "Грешка при зареждане на групи!\n" + error.message);
          });
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadData();
    });
  },
};
</script>

<style scoped>
/* .v-list-group {
  border: 1px solid blue;
} */
/* .v-list >>> .v-list-item {
  background-color: lightblue;
  border-radius: 25px 25px 0px 0px;
  cursor: pointer;
} */
</style>
