<template>
  <div>
    <v-app-bar app clipped-left dense :height="menuHeight">
      <v-menu offset-y rounded="0">
        <template v-slot:activator="{ attrs, on }">
          <v-btn class="subtitle-2" v-bind="attrs" v-on="on" text :width="menuWidth" dense><v-icon class="mr-2">mdi-briefcase-arrow-left-right-outline</v-icon>{{ "Операции" }} </v-btn>
        </template>
        <v-list dense class="ma-0 pa-0">
          <template v-for="item in listOperations">
            <v-list-item :key="item.name + 'oi'" @click="goTo(item.path)">
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item>
            <v-divider :key="item.name + 'od'"></v-divider>
          </template>
        </v-list>
      </v-menu>
      <v-divider vertical></v-divider>
      <v-menu offset-y rounded="0">
        <template v-slot:activator="{ attrs, on }">
          <v-btn class="subtitle-2" v-bind="attrs" v-on="on" text :width="menuWidth" dense><v-icon class="mr-2">mdi-format-list-numbered</v-icon> {{ "Номенклатура" }} </v-btn>
        </template>
        <v-list dense class="ma-0 pa-0">
          <template v-for="item in listBase">
            <v-list-item :key="item.name + 'bi'" @click="goTo(item.path)">
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item>
            <v-divider :key="item.name + 'bd'"></v-divider>
          </template>
        </v-list>
      </v-menu>
      <v-divider vertical></v-divider>
      <v-menu offset-y rounded="0">
        <template v-slot:activator="{ attrs, on }">
          <v-btn v-bind="attrs" v-on="on" text :width="menuWidth"><v-icon class="mr-2">mdi-file-table-box-multiple-outline</v-icon> {{ "Справки" }} </v-btn>
        </template>
        <v-list dense class="ma-0 pa-0">
          <template v-for="item in listReports">
            <v-list-item :key="item.name + 'ri'" @click="goTo(item.path)">
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item>
            <v-divider :key="item.name + 'rd'"></v-divider>
          </template>
        </v-list>
      </v-menu>
      <v-divider vertical></v-divider>
      <v-menu offset-y rounded="0">
        <template v-slot:activator="{ attrs, on }">
          <v-btn v-bind="attrs" v-on="on" text :width="menuWidth"><v-icon class="mr-2">mdi-account-cog-outline</v-icon>{{ "Настройки" }} </v-btn>
        </template>
        <v-list dense class="ma-0 pa-0">
          <template v-for="item in listSettings">
            <v-list-item :key="item.name + 'si'" @click="goTo(item.path)">
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item>
            <v-divider :key="item.name + 'sd'"></v-divider>
          </template>
        </v-list>
      </v-menu>
      <v-divider vertical></v-divider>
      <v-btn text :width="menuWidth"><v-icon class="mr-2">mdi-help-network-outline</v-icon> {{ "Помощ" }} </v-btn>
      <v-divider vertical></v-divider>
      <v-spacer></v-spacer>
      <v-icon class="ma-0 pa-0" :color="!$vuetify.theme.dark ? 'black' : ''" @click="$vuetify.theme.dark = !$vuetify.theme.dark">mdi-white-balance-sunny </v-icon>
      <!-- <v-menu>
        <v-list-item><v-icon>mdi-plus</v-icon><v-list-item-action>Тралала</v-list-item-action></v-list-item>
      </v-menu>
      <v-list-group title="Номенклатура">
        <v-list-item><v-icon>mdi-plus</v-icon><v-list-item-action>Тралала</v-list-item-action></v-list-item>
        <v-list-item>Тралала 2</v-list-item>
      </v-list-group>
      <v-list-group title="Справки">
        <v-list-item> </v-list-item>
      </v-list-group> -->
    </v-app-bar>
    <v-main>
      <transition>
        <keep-alive>
          <router-view class="align-center ma-0 pa-0" style="overflow-y: auto"> </router-view>
        </keep-alive>
      </transition>
    </v-main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuWidth: 180,
      listOperations: [
        { name: "Доставка", path: "Delivery" },
        { name: "Оферта", path: "Offer" },
        { name: "Заявка", path: "Request" },
        { name: "Ревизия", path: "Revision" },
      ],
      listBase: [
        { name: "Артикули", path: "Items" },
        { name: "Артикулни групи", path: "ItemsGroups" },
        { name: "Партньори", path: "Partners" },
        { name: "Групи партньори", path: "PartnersGroups" },
        { name: "Маси", path: "Tables" },
        { name: "Сметки", path: "Bills" },
        { name: "Мерни единици", path: "Measures" },
        { name: "Марки артикули", path: "Brands" },
        { name: "Модели артикули", path: "Models" },
      ],
      listReports: [
        { name: "Продажби", path: "ReportSales" },
        { name: "Доставки", path: "ReportSupplies" },
        { name: "Наличности", path: "ReportAvailablility" },
        { name: "Оферти", path: "ReportOffers" },
        { name: "Заявки", path: "ReportRequests" },
        { name: "Ревизии", path: "ReportRevisions" },
      ],
      listSettings: [
        { name: "Интерфейс", path: "Interface" },
        { name: "Роли", path: "Roles" },
        { name: "Права", path: "Permissions" },
      ],
    };
  },
  methods: {
    goTo(path) {
      alert("goto " + path);
      if (path.indexOf("Report") > -1) {
        this.$router.push({
          name: "Reports",
        });
      }
    },
  },
};
</script>

<style scoped>
.v-list > .v-list-item {
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>
