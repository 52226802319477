<template>
  <v-dialog v-model="isShown" :width="isSmall ? 320 : 400" z-index="99999" persistent>
    <v-form ref="refEditForm">
      <v-card class="ma-0 pa-0" align="center" justify="center">
        <v-card-title align="center" class="align-center">{{ title }}</v-card-title>
        <v-card-actions v-for="(h, index) in getHeaders()" :key="index" class="ma-2 pa-0">
          <v-checkbox v-if="h.type == headertypes.bool" v-model="item[h.value]" :label="h.text"> </v-checkbox>
          <my-text-field v-else-if="h.type == headertypes.int" :hide-details="true" v-model.number="item[h.value]" :label="h.text" type="number"></my-text-field>
          <my-text-field v-else-if="h.type == headertypes.money" :hide-details="true" v-model.number="item[h.value]" :label="h.text" type="number"></my-text-field>
          <my-text-field v-else-if="h.type == headertypes.qty" :hide-details="true" v-model.number="item[h.value]" :label="h.text" type="number"></my-text-field>
          <v-select
            v-else-if="h.type == headertypes.array"
            v-model="item[h.value]"
            @change="changeArrayVal(item[h.value])"
            class="ma-0 my-4 pa-0"
            :hide-details="true"
            dense
            :label="h.text"
            :items="h.items.data"
            :item-value="h.items.value"
            :item-text="h.items.text"
            :multiple="!!h.items.multiple"
          ></v-select>
          <v-select v-else-if="h.type == headertypes.array_of_objects" v-model="item[h.value]" class="ma-0 my-4 pa-0" :hide-details="true" dense :label="h.text" :items="h.items.data" :item-value="h.items.value" :item-text="h.items.text" :multiple="!!h.items.multiple"></v-select>
          <v-file-input v-else-if="h.type == headertypes.image" v-model="item[h.value]"></v-file-input>
          <my-text-field v-else :hide-details="true" v-model="item[h.value]" :label="h.text"></my-text-field>
        </v-card-actions>
        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <my-button large width="auto" class="ma-2 pa-0" @click="close(true)">ОК</my-button>
            <v-spacer></v-spacer>
            <my-button large width="auto" class="ma-2 pa-0" @click="close(false)">Отказ</my-button>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import MyButton from "./MyButton.vue";
export default {
  components: { MyButton },
  props: {},
  data() {
    return {
      title: "Заглавие",
      item: null,
      headers: [],
      isShown: false,
      callback: null,
    };
  },
  methods: {
    getHeaders() {
      return this.headers.filter((h) => !h.readonly);
    },
    changeArrayVal(a) {
      if (!a) a = [];
      this.$nextTick(() => {
        a.sort((a, b) => {
          return a - b;
        });
      });
    },
    show(title, item, headers, onCloseCallback) {
      this.title = title;
      this.item = item;
      this.headers = headers;
      this.callback = onCloseCallback;
      this.isShown = true;
    },
    close(confirm) {
      if (confirm) {
        if (this.$refs.refEditForm.validate()) {
          this.callback(this.item);
        } else {
          this.showMessage("error", "Некоректна информация!");
        }
      } else {
        this.callback(null);
      }
      this.isShown = false;
    },
  },
  mounted() {},
};
</script>

<style></style>
