import Vue from "vue";
import Vuetify from "vuetify/lib";
import router from "./router/routes";
import App from "./App.vue";
import MyButton from "./components/MyButton.vue";
import VueDraggableResizable from "vue-draggable-resizable";
import MyTextFieldVue from "./components/MyTextField.vue";
import MyGrid from "./components/MyGrid.vue";
import "vue-draggable-resizable/dist/VueDraggableResizable.css";
import LoadingPlugin from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueSocialSharing from "vue-social-sharing";
import VueColumnsResizableVuetify from "vue-columns-resizable-vuetify";

var global_booleans = {
  showKeyboard: true,
};

var gglobals = {
  CurrentUser: {},
};
export let globals = gglobals;
export const header_types = {
  string: 0,
  bool: 1,
  int: 2,
  money: 3,
  qty: 4,
  phone: 5,
  array: 6,
  array_of_objects: 7,
  image: 8,
};

Vue.mixin({
  data() {
    return {
      // loader: LoadingPlugin,
      GG: global_booleans,
      Loader: null,
      Message: null,
      Editor: null,
      YesNoDialog: null,
      ImageView: null,
      rules_password: [(r) => !!r || "Моля въведете парола", (r) => (!!r && r.length > 4) || "Прекалено къса парола!"],
      rules_required: [(r) => !!r || "Задължително поле", (r) => (!!r && r.length > 2) || "Прекалено късо!"],
      rules_email: [(v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Невалиен e-mail!"],
    };
  },
  computed: {
    headertypes() {
      return header_types;
    },
    menuHeight() {
      return 36;
    },
    mainHeight() {
      return "calc(100% - 1px)";
    },
    isSmall() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
        case "md":
        case "lg":
        case "xl":
        default:
          return false;
      }
    },
  },
  methods: {
    isValidUrl(urlForCheck) {
      let url = null;
      try {
        url = new URL(urlForCheck);
      } catch {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    phoneCall(phone) {
      window.open("tel:" + phone);
    },
    showImage(defaultImageUrl, imageId, editable, onCloseCallback) {
      this.ImageView.show(defaultImageUrl, imageId, editable, onCloseCallback);
    },
    showEditor(title, item, headers, onCloseCallback) {
      this.Editor.show(title, item, headers, onCloseCallback);
    },
    showYesNoDialog(text, callback) {
      this.YesNoDialog.show(text, callback);
    },
    showMessage(type, text) {
      this.hideLoader();
      this.$nextTick(() => {
        this.Message.show(type, text);
      });
    },
    hideMessage() {
      this.Message.hide();
    },
    showLoader(title, task) {
      this.Loader.show(title, task);
    },
    hideLoader() {
      this.Loader.hide();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$root.$children[0] && this.$root.$children[0].$refs) {
        if (!this.Loader) {
          this.Loader = this.$root.$children[0].$refs.refLoader;
        }
        if (!this.YesNoDialog) {
          this.YesNoDialog = this.$root.$children[0].$refs.refYesNoDialog;
        }
        if (!this.Message) {
          this.Message = this.$root.$children[0].$refs.refMessage;
        }
        if (!this.Editor) {
          this.Editor = this.$root.$children[0].$refs.refEditor;
        }
        if (!this.ImageView) {
          this.ImageView = this.$root.$children[0].$refs.refImageView;
        }
      }
    });
  },
});

Vue.use(VueColumnsResizableVuetify);
Vue.use(VueSocialSharing);

// import VueHtmlToPaper from "vue-html-to-paper";
// const options = {
//   name: "_blank",
//   specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
//   styles: ["https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css", "https://unpkg.com/kidlat-css/css/kidlat.css"],
// };
// Vue.use(VueHtmlToPaper, options);
// Vue.use(VueHtmlToPaper);

// import VueHtml2Canvas from "vue-html2canvas";
// Vue.use(VueHtml2Canvas);

Vue.component("viber-icon", require("./assets/viber.svg"));

Vue.component("vue-draggable-resizable", VueDraggableResizable);
Vue.component("my-text-field", MyTextFieldVue);
Vue.component("my-button", MyButton);
Vue.component("my-grid", MyGrid);
Vue.use(Vuetify);
Vue.use(LoadingPlugin, {
  loader: "dots",
  container: null,
  canCancel: false,
  opacity: 0.8,
  width: 164,
  height: 164,
  zIndex: 99999,
});

const vuetify = new Vuetify({
  theme: {
    options: { customProperties: true },
    // disable: true,
    themes: {
      light: {
        primary: "#006064",
        secondary: "#424242",
        accent: "#00E5FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        anchor: "#8c9eff",
      },
    },
  },
  icons: {
    iconfont: "mdi",
    values: {
      viber: {
        component: "viber-icon",
        props: {
          class: "custom-icon",
        },
      },
    },
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
