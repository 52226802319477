import Vue from "vue";
import VueRouter from "vue-router";
import StartupPage from "@/views/StartupPage.vue";
import LoginView from "@/views/LoginView.vue";
import BackOfficeView from "@/views/BackOfficeView.vue";
import ReportsView from "@/views/ReportsView.vue";
import MenuView from "@/webviews/MenuView.vue";
import UserRegister from "@/webviews/UserRegister.vue";
import WebLoginView from "@/webviews/WebLoginView.vue";
import WebAdminView from "@/webviews/WebAdminView.vue";
import WebUserView from "@/webviews/UserView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: StartupPage,
    props: true,
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: LoginView,
    props: true,
  },
  {
    path: "/webadmin",
    name: "WebAdmin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: WebAdminView,
    props: true,
  },
  {
    path: "/webuser",
    name: "WebUser",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: WebUserView,
    props: true,
  },
  {
    path: "/weblogin",
    name: "WebLogin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: WebLoginView,
    props: true,
  },
  {
    path: "/register",
    name: "Register",
    component: UserRegister,
    props: true,
  },
  {
    path: "/menu/:username",
    name: "Menu",
    component: MenuView,
    props: true,
  },
  {
    path: "/backoffice",
    name: "BackOffice",
    component: BackOfficeView,
    props: true,
    children: [
      {
        title: "Отчети",
        path: "/reports",
        name: "Reports",
        component: ReportsView,
        meta: {},
        props: true,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
